<template>
  <div class="bg-shop-summary">
    <el-card shadow="hover" class="x-card-title-slot mb-3">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          商品数据
        </div>
        <div class="col-auto">起止日期：</div>
        <div class="col-auto mr-3">
          <el-date-picker
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            style="width: 220px;"
            v-model="daterangeDate"
            type="daterange"
            size="medium"
            @change="daterangeDateChange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </div>
        <div class="col-auto">
          <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <el-button type="text" :loading="importLoading" @click="downZd()">
            导出{{ typeList[type - 2].label }}商品
          </el-button>
        </div>
      </div>

      <div class="btnTab">
        <el-button
          size="small"
          :type="type === item.value ? 'primary' : 'default'"
          @click="type = item.value; handleSearch()"
          v-for="(item,index) in typeList"
          :key="index"
        >
          {{ item.label }}
        </el-button>
      </div>
      <div id="priceList" :style="{height: '50vh'}"></div>
    </el-card>

    <el-card shadow="hover" class="x-card-title-slot mb-3">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          月度商品统计
        </div>
        <div class="col-auto">
          <el-date-picker
            size="medium"
            v-model="time"
            @change="changeTime"
            type="month"
            placeholder="请选择月份"
          ></el-date-picker>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <el-button type="text" :loading="importLoading1" @click="downZd1()">导出月度商品统计</el-button>
        </div>
      </div>

      <en-table-layout :toolbar="false" :pagination="false" :tableData="pageData.data" :loading="loading">
        <template slot="table-columns">
          <el-table-column label="时间">
            <template slot-scope="scope">{{
                scope.row.year ? (scope.row.year + '-') : ''
              }}{{ scope.row.month ? (scope.row.month + '-') : '' }}{{ scope.row.time }}
            </template>
          </el-table-column>
          <el-table-column label="新增商品">
            <template slot-scope="scope">{{ scope.row.goodsNewNum }}</template>
          </el-table-column>
          <el-table-column label="被上架商品">
            <template slot-scope="scope">{{ scope.row.enableGoodsNewNum }}</template>
          </el-table-column>
          <el-table-column label="曝光商品">
            <template slot-scope="scope">{{ scope.row.goodsExposureNum }}</template>
          </el-table-column>
          <el-table-column label="被访问商品">
            <template slot-scope="scope">{{ scope.row.goodsPvNum }}</template>
          </el-table-column>
          <el-table-column label="被下单商品">
            <template slot-scope="scope">{{ scope.row.goodsOrderNum }}</template>
          </el-table-column>
          <!-- <el-table-column label="累计商品总数">
            <template slot-scope="scope">{{ scope.row.goodsNumSum }}</template>
          </el-table-column> -->
        </template>
      </en-table-layout>
    </el-card>

    <el-card shadow="hover" class="x-card-title-slot">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          年度商品统计
        </div>
        <div class="col-auto">
          <el-date-picker v-model="time1" @change="changeTime1" type="year" placeholder="请选择年"></el-date-picker>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <el-button type="text" :loading="importLoading2" @click="downZd2()">导出年度商品统计</el-button>
        </div>
      </div>

      <en-table-layout :toolbar="false" :pagination="false" :tableData="pageData1.data" :loading="loading">
        <template slot="table-columns">
          <el-table-column label="时间">
            <template slot-scope="scope">
              {{ scope.row.year ? (scope.row.year + '-') : '' }}
              {{ scope.row.month ? (scope.row.month + '-') : '' }}
              {{ scope.row.time }}
            </template>
          </el-table-column>
          <el-table-column label="新增商品">
            <template slot-scope="scope">{{ scope.row.goodsNewNum }}</template>
          </el-table-column>
          <el-table-column label="被上架商品">
            <template slot-scope="scope">{{ scope.row.enableGoodsNewNum }}</template>
          </el-table-column>
          <el-table-column label="曝光商品">
            <template slot-scope="scope">{{ scope.row.goodsExposureNum }}</template>
          </el-table-column>
          <el-table-column label="被访问商品">
            <template slot-scope="scope">{{ scope.row.goodsPvNum }}</template>
          </el-table-column>
          <el-table-column label="被下单商品">
            <template slot-scope="scope">{{ scope.row.goodsOrderNum }}</template>
          </el-table-column>
          <!-- <el-table-column label="累计商品总数">
            <template slot-scope="scope">{{ scope.row.goodsNumSum }}</template>
          </el-table-column> -->
        </template>
      </en-table-layout>
    </el-card>
  </div>
</template>

<script>
import * as API_buyAnyalysis from '@/api/buyAnyalysis';
import {CategoryPicker} from '@/components';
import {Foundation} from '@/../ui-utils';
import {handleDownload} from '@/utils';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: 'buyAnalysis',
  components: {
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker
  },
  data() {
    return {
      type: 2,
      list1: [],
      typeList: [
        // { label: "总数", value: 1 },
        {
          label: '新增',
          value: 2
        },
        {
          label: '被上架',
          value: 3
        },
        {
          label: '曝光',
          value: 4
        },
        {
          label: '被访问',
          value: 5
        },
        {
          label: '被下单',
          value: 6
        }
      ],
      time: new Date(),
      time1: new Date(),
      importLoading: false,
      importLoading1: false,
      importLoading2: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              start.setTime(start.getTime());
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近7日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近15日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近30日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      /** 列表loading状态 */
      loading: false,

      daterangeDate: '',

      pageData1: {data: []},
      pageData: {data: []},

      /** 图表参数*/
      params: {},
      data: {},

      tableHeight: document.body.clientHeight / 2
    };
  },
  created() {
    this.resetTimeParams();

    this.GET_PriceStatistics();

    this.GET_PriceStatistics1(
      'year',
      Foundation.unixToDate(this.params.end_time, 'yyyy')
    );
    this.GET_PriceStatistics1(
      'month',
      Foundation.unixToDate(this.params.end_time, 'yyyy'),
      Foundation.unixToDate(this.params.end_time, 'M')
    );
  },
  mounted() {
    window.onresize = this.countTableHeight;
    this.$nextTick(() => {
      this.priceListChart = this.$echarts.init(
        document.getElementById('priceList')
      );
    });
  },
  methods: {
    resetTimeParams() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);

      this.params.start_time = parseInt(start / 1000);
      this.params.end_time = parseInt(end / 1000);
    },
    downZd() {
      let data = {...this.data};
      this.importLoading = true;
      let response = [];
      data.xAxis.forEach(item => {
        response.push({date: item});
      });
      data.series.data.forEach((item, index) => {
        response[index]['ys'] = item;
      });
      let tHeaders = ['日期', '商品'];
      let filterVals = ['date', 'ys'];
      handleDownload(
        response,
        tHeaders,
        filterVals,
        this.typeList.find(item => {
          return item.value === this.type;
        }).label + '商品数据'
      );
      this.importLoading = false;
    },
    downZd1() {
      this.importLoading1 = true;
      let tHeaders = [
        '时间',
        '新增商品',
        '被上架商品',
        '曝光商品',
        '被访问商品',
        '被下单商品',
        // "累计商品总数"
      ];
      let filterVals = [
        'time',
        'goodsNewNum',
        'enableGoodsNewNum',
        'goodsExposureNum',
        'goodsPvNum',
        'goodsOrderNum',
        // "goodsNumSum"
      ];
      const name = `${this.time.getFullYear()}年${this.time.getMonth() + 1}月商品统计`;
      handleDownload(this.pageData.data, tHeaders, filterVals, name);
      this.importLoading1 = false;
    },
    downZd2() {
      this.importLoading2 = true;
      let tHeaders = [
        '时间',
        '新增商品',
        '被上架商品',
        '曝光商品',
        '被访问商品',
        '被下单商品',
        // "累计商品总数"
      ];
      let filterVals = [
        'time',
        'goodsNewNum',
        'enableGoodsNewNum',
        'goodsExposureNum',
        'goodsPvNum',
        'goodsOrderNum',
        // "goodsNumSum"
      ];
      const name = `${this.time.getFullYear()}年商品统计`;
      handleDownload(this.pageData1.data, tHeaders, filterVals, name);
      this.importLoading2 = false;
    },
    changeTime(end) {
      this.GET_PriceStatistics1(
        'month',
        Foundation.unixToDate(parseInt(end / 1000), 'yyyy'),
        Foundation.unixToDate(parseInt(end / 1000), 'M')
      );
    },
    changeTime1(end) {
      this.GET_PriceStatistics1(
        'year',
        Foundation.unixToDate(parseInt(end / 1000), 'yyyy')
      );
    },
    daterangeDateChange(val) {
      if (val) {
        this.params.start_time = val ? parseInt(val[0] / 1000) : '';
        this.params.end_time = val ? parseInt(val[1] / 1000) : '';
      } else {
        this.resetTimeParams();
      }

      this.GET_PriceStatistics();
    },

    /** 执行搜索 */
    handleSearch() {
      this.GET_PriceStatistics();
    },

    GET_PriceStatistics() {
      this.params.type = this.type;
      API_buyAnyalysis.goodsGoodsNum(this.params).then(response => {
        this.data = response;
        this.priceListChart.setOption({
          tooltip: {trigger: 'axis'},
          grid: {
            x: '50px',
            y: '80px',
            x2: '60px',
            y2: '30px'
          },
          legend: {
            show: true,
            orient: 'vertical',
            data: [{name: '数量'}],
            bottom: '10px'
          },
          // color: ["#7CB5EC"],
          toolbox: {
            feature: {
              magicType: {type: ['line', 'bar']},
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            name: '日期',
            type: 'category',
            boundaryGap: false,
            data: response.xAxis
          },
          yAxis: {
            name: '数量',
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: [
            {
              type: 'line',
              data: response.series.data,
              symbol: 'none', //这句就是去掉点的
              smooth: true, //这句就是让曲线变平滑的
              name: '商品数据',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: '#1A43A9'
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#9083EC'
                    },
                    {
                      offset: 1,
                      color: '#6EC2F1'
                    }
                  ])
                }
              },
              barWidth: 50
            }
          ]
        });
      });
    },
    GET_PriceStatistics1(cycle_type, year, month) {
      API_buyAnyalysis.goodsNumByCycleType({
        cycle_type,
        year,
        month
      }).then(response => {
        if (month) {
          response.forEach(item => {
            item.year = year;
            item.month = month;
          })
          this.pageData.data = response;
        } else {
          response.forEach(item => {
            item.year = year;
            item.month = month;
          })
          this.pageData1.data = response;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tipBox {
  background: #fff;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 5px;
}

.tipBox p {
  margin: 0;
  line-height: 18px;
  margin-bottom: 15px;
  font-size: 14px;
}

.conditions {
  display: inline-block;
  margin-right: 20px;
}

.conditions.right {
  float: right;
}

.conditions > > > .el-input {
  display: inline-block;
}

.conditions span {
  font-size: 14px;
  color: #606266;
}

.btnTab {
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.tipBox p {
  line-height: 24px;
  padding: 0;
  position: relative;
  padding-left: 18px;
  font-size: 24px;
  color: #393c41;
  margin-bottom: 30px;
}

.tipBox p:before {
  content: " ";
  display: block;
  width: 8px;
  height: 24px;
  background: #1a43a9;
  position: absolute;
  left: 0;
  top: 0;
}
</style>

